/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  // const social = data.site.siteMetadata?.social

  return (
    <div className="p-4 rounded-3 bg-800">
      <div className="bio row align-items-center">
        <div className="col-auto">
          <StaticImage
            className="bio-avatar mini rounded"
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/onur.jpg"
            width={50}
            height={50}
            quality={95}
            alt="Onur BOZ"
          />
        </div>
        <div className="col">
          {author?.name && (
            <p className="lead mb-0">
              Hi, I am <strong>{author.name}</strong>. {author?.summary || null}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Bio
